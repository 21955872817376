import type {Localizations} from '~/lib/type';

export const countries: Localizations = {
  default: {
    label: 'Spain (EUR €)',
    language: 'EN',
    country: 'ES',
    currency: 'EUR',
  },
  
};
